import { SvgProps } from '~/utils/types';

export const Eye = ({ className, title }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="22"
      fill="none"
      className={className}
      aria-hidden={title ? 'false' : 'true'}
    >
      {title && <title>{title}</title>}
      <path
        fill="currentColor"
        d="M16.965 21.24C7.888 21.24.36 12.55.36 11 .36 9.45 7.888.76 16.965.76 25.932.76 33.571 9.895 33.571 11c0 1.495-7.75 10.24-16.606 10.24ZM1.467 11c.332 1.33 7.14 9.134 15.498 9.134 8.248 0 14.89-7.805 15.499-9.133-.664-1.329-7.362-9.133-15.499-9.133-8.247 0-15.11 7.804-15.498 9.133Z"
      />
      <path
        fill="currentColor"
        d="M16.966 17.367c-3.653 0-6.642-2.99-6.642-6.643 0-.72.11-1.439.332-2.158.941-2.657 3.488-4.484 6.31-4.484 1.218 0 2.38.332 3.432.941l-.553.94a5.906 5.906 0 0 0-2.879-.774 5.535 5.535 0 0 0-5.258 3.764 6.232 6.232 0 0 0-.277 1.771c0 3.045 2.491 5.535 5.535 5.535 3.045 0 5.535-2.49 5.535-5.535a5.45 5.45 0 0 0-.166-1.328c-.055-.166-.055-.277-.11-.443l1.051-.332.167.498c.11.554.166 1.052.166 1.605 0 3.653-2.99 6.642-6.643 6.642Z"
      />
      <path
        fill="currentColor"
        d="M21.395 10.168a2.74 2.74 0 0 1-2.767-2.767c0-.277.055-.61.166-.886.387-1.107 1.44-1.882 2.601-1.882 1.218 0 2.27.775 2.602 1.882.11.277.166.554.166.886a2.74 2.74 0 0 1-2.768 2.767Zm0-4.428c-.72 0-1.328.443-1.55 1.107-.055.166-.11.332-.11.554 0 .94.72 1.66 1.66 1.66.941 0 1.661-.72 1.661-1.66 0-.167-.055-.332-.11-.499-.222-.719-.83-1.162-1.55-1.162Z"
      />
    </svg>
  );
};
