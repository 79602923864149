import { SvgProps } from '~/utils/types';

export const PreloaderLogo = ({ className, title }: SvgProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 36 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.06935 4.12308C3.21221 4.12308 4.13869 3.20009 4.13869 2.06154C4.13869 0.922982 3.21221 0 2.06935 0C0.926478 0 0 0.922982 0 2.06154C0 3.20009 0.926478 4.12308 2.06935 4.12308Z"
        fill="#222222"
      />
      <path
        d="M2.06935 23C3.21221 23 4.13869 22.077 4.13869 20.9385C4.13869 19.7999 3.21221 18.877 2.06935 18.877C0.926478 18.877 0 19.7999 0 20.9385C0 22.077 0.926478 23 2.06935 23Z"
        fill="#222222"
      />
      <path
        d="M33.5459 4.12308C34.6888 4.12308 35.6153 3.20009 35.6153 2.06154C35.6153 0.922982 34.6888 0 33.5459 0C32.403 0 31.4766 0.922982 31.4766 2.06154C31.4766 3.20009 32.403 4.12308 33.5459 4.12308Z"
        fill="#222222"
      />
      <path
        d="M17.8233 4.12308C18.9661 4.12308 19.8926 3.20009 19.8926 2.06154C19.8926 0.922982 18.9661 0 17.8233 0C16.6804 0 15.7539 0.922982 15.7539 2.06154C15.7539 3.20009 16.6804 4.12308 17.8233 4.12308Z"
        fill="#222222"
      />
      <path
        d="M9.95997 8.87893C11.1028 8.87893 12.0293 7.95595 12.0293 6.8174C12.0293 5.67884 11.1028 4.75586 9.95997 4.75586C8.8171 4.75586 7.89062 5.67884 7.89062 6.8174C7.89062 7.95595 8.8171 8.87893 9.95997 8.87893Z"
        fill="#222222"
      />
      <path
        d="M17.8233 23C18.9661 23 19.8926 22.077 19.8926 20.9385C19.8926 19.7999 18.9661 18.877 17.8233 18.877C16.6804 18.877 15.7539 19.7999 15.7539 20.9385C15.7539 22.077 16.6804 23 17.8233 23Z"
        fill="#222222"
      />
      <path
        d="M25.7197 8.87893C26.8637 8.87893 27.791 7.95595 27.791 6.8174C27.791 5.67884 26.8637 4.75586 25.7197 4.75586C24.5758 4.75586 23.6484 5.67884 23.6484 6.8174C23.6484 7.95595 24.5758 8.87893 25.7197 8.87893Z"
        fill="#222222"
      />
      <path
        d="M9.96192 18.3028C11.1059 18.3028 12.0332 17.3798 12.0332 16.2412C12.0332 15.1027 11.1059 14.1797 9.96192 14.1797C8.81798 14.1797 7.89062 15.1027 7.89062 16.2412C7.89062 17.3798 8.81798 18.3028 9.96192 18.3028Z"
        fill="#222222"
      />
      <path
        d="M33.5459 23C34.6888 23 35.6153 22.077 35.6153 20.9385C35.6153 19.7999 34.6888 18.877 33.5459 18.877C32.403 18.877 31.4766 19.7999 31.4766 20.9385C31.4766 22.077 32.403 23 33.5459 23Z"
        fill="#222222"
      />
      <path
        d="M25.7197 18.3028C26.8637 18.3028 27.791 17.3798 27.791 16.2412C27.791 15.1027 26.8637 14.1797 25.7197 14.1797C24.5758 14.1797 23.6484 15.1027 23.6484 16.2412C23.6484 17.3798 24.5758 18.3028 25.7197 18.3028Z"
        fill="#222222"
      />
      <path
        d="M17.8213 13.5703C18.9652 13.5703 19.8926 12.6474 19.8926 11.5088C19.8926 10.3702 18.9652 9.44727 17.8213 9.44727C16.6774 9.44727 15.75 10.3702 15.75 11.5088C15.75 12.6474 16.6774 13.5703 17.8213 13.5703Z"
        fill="#222222"
      />
    </svg>
  );
};
